.book{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.dashboard{
    display: block;
    height: 100vh;
    padding-top: 150px;
}
.book form,
.login form{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px 60px;
    border-radius: 10px;
    max-width: 350px;
    width: 100%;
}
.book input,
.login input{
    padding: 8px 0;
    width: 100%;
}
.book select{
    width: 100%;
}
.book input[type="date"]{
    width: 100%;
}
.book form h1,
.login form h1
{
    text-align: center;
}

.book button, 
.login button{
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    background-color: #470FF4;
    color: #FFFAFF;
}
.second-footer{
    background: #1b1b1b;
}
.formInput{
    display: flex;
    flex-direction: column;
    margin: 15px 0 ;
    width: 280px;
}
.formInput label{
    margin-bottom: 0;
    font-size: 16px;
    color: #B0C4B1;
    text-transform: capitalize;
}